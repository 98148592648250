import React from 'react';

function ContactSection() {
  return (
    <div className="flex flex-col gap-5">
      <div className="">
        <div className="flex flex-col gap-7">
          <h4 className="text-[20px] text-black">Contact Us</h4>
          <ul className="flex flex-col gap-5 text-[14px] text-gray-400">
            <li className="flex flex-col gap-2">
              <h5 className="text-[16px] text-black">Chennai</h5>
              <li>
                <a href="tel:+914447890985">+91 44 4789 0985</a>
              </li>
              <li>
                <a href="tel:+919363021066">+91 93630 21066</a>
              </li>
              <li>
                <a href="mailto:hello@mypropertyplus.com">
                  hello@mypropertyplus.com
                </a>
              </li>
            </li>

            <li className="flex flex-col gap-2">
              <h5 className="text-[16px] text-black">Madurai</h5>
              <li>
                <a href="tel:+919363031066">+91 93630 31066</a>
              </li>

              <li className="flex flex-col gap-2">
                <h5 className="text-[16px] text-black">UAE</h5>
                <li>
                  <a href="tel:+971559116083">+971 55 911 6083</a>
                </li>
              </li>
            </li>
          </ul>
        </div>
      </div>
      {/* End .col */}

      <div className="">
        <div className="flex flex-col gap-7">
          <h4 className="text-[20px] text-black">Contact Us</h4>
          <ul className="flex flex-col gap-5 text-[14px] text-gray-400">
            <li className="flex flex-col gap-2">
              <h5 className="text-[16px] text-black">
                My Property Plus - Chennai - India
              </h5>
              <a href="#">
                New No.134/2, Old No.106/2, <br />
                First floor, Lake View Road, <br />
                West Mambalam, <br />
                Chennai 600033
              </a>
            </li>

            <li className="flex flex-col gap-2">
              <h5 className="text-[16px] text-black">
                My Property Plus - Dubai - UAE
              </h5>
              <a href="#">
                {' '}
                Flat #515, <br />
                Al Hai Building, Al Mankhool, <br />
                Dubai, UAE
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* End .col */}
    </div>
  );
}

export default ContactSection;

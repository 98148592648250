// useAuth.js
import { useState } from 'react';

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);

  // Function to check if the user is authenticated
  const checkAuthentication = () => {
    // Check if the user is already authenticated (e.g., on page reload)
    if (authenticated) {
      return true;
    }

    // Check localStorage for the hardcoded admin credentials (username and password)
    const storedUsername = localStorage.getItem('adminUsername');
    const storedPassword = localStorage.getItem('adminPassword');

    // Replace these with your preferred hardcoded username and password
    const hardcodedUsername = 'admin';
    const hardcodedPassword = 'password';

    // Compare the stored credentials with the hardcoded ones
    if (storedUsername === hardcodedUsername && storedPassword === hardcodedPassword) {
      setAuthenticated(true);
      return true;
    }

    return false;
  };

  // Function to handle login (set authenticated to true)
  const login = () => {
    // Perform the actual login process (e.g., API call, etc.)

    // For this example, we'll set the hardcoded admin credentials in localStorage
    localStorage.setItem('adminUsername', 'admin');
    localStorage.setItem('adminPassword', 'password');

    setAuthenticated(true);
  };

  // Function to handle logout (set authenticated to false)
  const logout = () => {
    // Perform the actual logout process (e.g., API call, etc.)

    // For this example, we'll remove the admin credentials from localStorage
    localStorage.removeItem('adminUsername');
    localStorage.removeItem('adminPassword');

    setAuthenticated(false);
  };

  return { authenticated, login, logout, checkAuthentication };
}
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useAuth from './useAuth';

function Admin() {
    const { authenticated, checkAuthentication, logout } = useAuth();
    const navigate = useNavigate();


    // Check if the user is authenticated before rendering the component
    if (!checkAuthentication()) {
        // Redirect to a login page or show a login form here if the user is not authenticated
        return navigate("/signin");
    }

    const handleLogout = () => {
        logout();
      };

    return (
        <div className='flex flex-col gap-5 items-center justify-center mt-10'>
            <div className='w-[200px]'>
                <button
                    onClick={handleLogout}
                    className="bg-red-500 text-white text-sm px-3 py-2 rounded-lg w-[100%]"
                >
                    Logout
                </button>
            </div>
            <div className='flex flex-col items-center justify-center gap-5 m-5 w-[200px] text-center'>
                <Link to={"/admin/blog/allblog"} className='bg-blue-500 text-white w-[100%] text-xl px-3 py-2 font-semibold rounded-3xl'>
                    Blogs
                </Link>
                <Link to={"/admin/property/allproperty"} className='bg-blue-500 text-white w-[100%] text-xl px-3 py-2 font-semibold rounded-3xl'>
                    Properties
                </Link>
            </div>
        </div>
    )
}

export default Admin
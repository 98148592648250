// SignInCard.js
import React, { useState } from 'react';
import useAuth from './useAuth'; // Import the custom hook
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const { authenticated, login, logout } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    // Perform login based on the input username and password
    // For this example, we'll use the hardcoded admin credentials
    if (username === 'admin' && password === 'password') {
      login();
    } else {
      alert('Invalid credentials');
    }
  };

  const handleLogout = () => {
    logout();
  };

  if(authenticated){
    navigate("/admin");
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-8 max-w-sm mx-auto mt-[100px]">
      {authenticated ? (
        <div>
          <h2 className="text-xl font-semibold mb-4">Welcome, Admin!</h2>
          <button
            onClick={handleLogout}
            className="bg-red-500 text-white text-sm px-3 py-2 rounded-lg"
          >
            Logout
          </button>
        </div>
      ) : (
        <div className=''>
          <h2 className="text-xl font-semibold mb-4">Sign In</h2>
          <div className="mb-4">
            <label htmlFor="username" className="block text-sm font-medium">
              Username
            </label>
            <input
              type="text"
              id="username"
              className="mt-1 px-4 py-2 border rounded-lg w-full"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="mt-1 px-4 py-2 border rounded-lg w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            onClick={handleLogin}
            className="bg-blue-500 text-white text-sm px-4 py-2 rounded-lg"
          >
            Sign In
          </button>
        </div>
      )}
    </div>
  );
};

export default SignIn;

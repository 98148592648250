import React from 'react';
import { Link } from 'react-router-dom';
import photo from '../../assets/photo.jpg';

function GridCard(props) {
  const dateString = props.date;
  const date = new Date(dateString);
  const formattedDate = date.toDateString();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  }

  return (
    <Link
      to={'/properties/single/' + props.id}
      className="rounded-[10px] hover:scale-105 shadow-xl h-[100%] hover:shadow-2xl duration-500 cursor-pointer p-4 flex flex-col gap-4 w-[300px]"
    >
      <div className="w-[270px] h-[250px]">
        <img
          src={props.photo !== undefined ? props.photo : photo}
          className="w-[270px] h-[250px] object-cover rounded-[10px]"
          alt=""
        />
      </div>

      <div className="flex flex-col gap-2">
        <div className="text-one">{props?.catagory}</div>
        <div className="font-bold">{props?.name}</div>
        <div className="text-gray-500">{props?.address}</div>
        <div className="pb-2 text-gray-500">
          Beds: {props?.bedrooms} Baths: {props?.bathrooms} sqft:{' '}
          {props?.propertySize}{' '}
        </div>
        <hr />
        <div className="pt-2 font-bold flex flex-row items-center justify-between">
          <span>Admin</span>
          <span> {formatDate(props.date)}</span>
        </div>
      </div>
    </Link>
  );
}

export default GridCard;

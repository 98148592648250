import React from 'react'

function ServiceAbout(props) {
  return (
    <div className='flex flex-col gap-7 items-center justify-center w-[90%] md:w-[60%]'>
        <div className='text-[25px] md:text-[40px] font-semibold'>
            We Can Offer You Wide Range Of Services
        </div>

        <div className='grid grid-cols-1 md:grid-cols-[2fr_1fr] items-center justify-center gap-10'>
            <img className='w-[600px] h-[400px] object-cover rounded-[30px]' src={props.img} alt="" />
            <div className='flex flex-col gap-4'>
                <span className='text-[20px] md:text-[30px] font-semibold'>{props.title}</span>
                <span className='text-[15px] md:text-auto'>{props.des}</span>
            </div>
        </div>
        <div></div>
    </div>
  )
}

export default ServiceAbout
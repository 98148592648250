import React, { useEffect, useState } from 'react'
import img1 from "../../assets/1.png"
import img2 from "../../assets/2.png";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

function Hero2() {
    const [active, setActive] = useState(1);

    function changeActive() {
        if (active === 1) setActive(2);
        else setActive(1);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            changeActive();
        }, 5000);
        return () => clearInterval(interval);
    }, [active]);

    // setInterval(changeActive, 6000);

    return (
        <div className='relative md:h-[660px] sm:h-[660px] overflow-hidden'>
            <div className={active === 1 ? "h-[650px] duration-1000 ease-in" : "hidden duration-1000 ease-in"}>
                <div className='flex flex-col absolute text-white py-[50%] md:py-[15%] px-[10%]'>
                    <span className='text-[25px] md:text-[55px] font-bold'>PROPERTY SERVICES COMPANY</span>
                    <span className='text-[15px] md:text-[20px] font-medium'>One Stop Solutions For All Property NeedsWe are recognized to be a well-renowned company that is committed to offering the best services to fulfill yproperty needs.</span>
                </div>
                <img src={img1} className='z-0 object-cover w-full h-full sm:object-cover sm:w-full sm:h-screen' />
            </div>
            <div className={active === 2 ? "h-[650px] duration-1000" : "duration-1000 hidden"}>
                <div className='flex flex-col absolute text-white py-[50%] md:py-[15%] px-[10%]'>
                    <span className='text-[25px] md:text-[55px] font-bold'>PROPERTY MANAGEMENT COMPANY</span>
                    <span className='text-[15px] md:text-[20px] font-medium'>Solution For All Property Management Problems We are a popular service provider offering Chennai's best property management services. Call us today for more details.</span>
                </div>
                <img src={img2} className='z-0 object-cover w-full h-full sm:object-cover sm:w-full sm:h-screen' />
            </div>
            <div className='z-[999] absolute bottom-[-70px] left-[-70px] m-14 flex-row gap-4 items-center font-semibold text-[25px] text-white hidden sm:flex'>
                <div className='flex flex-row gap-10 px-10 py-7 text-gray-400 rounded-[10px] bg-white'>
                    <button onClick={changeActive} className='hover:text-black'><AiOutlineArrowLeft /></button>
                    <button onClick={changeActive} className='hover:text-black'><AiOutlineArrowRight /></button>
                </div>
                <span>0{active} / <span className='text-[23px]'>02</span></span>
            </div>

            <a href="https://api.whatsapp.com/send?phone=+918637621066&text=Hi%20My%20Property%20Plus%20Team..!" target='_blank' className='rounded-full z-[999] bottom-0 right-0 md:bottom-10 md:right-10 p-2 m-2 shadow-xl bg-green-500 text-white font-bold text-[30px] fixed'>
                <BsWhatsapp />
            </a>
        </div>


    )
}

export default Hero2
import React from 'react'
import photo from "../../assets/inner-page.jpg"

function CommonHero(props) {
  return (
    <div className='flex flex-col items-start justify-start px-4 md:px-32 py-32 text-white' style={{backgroundImage: `url(${photo})`, backgroundSize: "cover", overflow: "hidden"}}>
        <span className='text-[20px] md:text-[30px]'>{props.subTitle}</span>
        <span className='text-[40px] md:text-[65px] font-bold'>{props.title}</span>
    </div>
  )
}

export default CommonHero
import React, { useState } from 'react';
import { BsFacebook, BsInstagram, BsYoutube } from 'react-icons/bs';
import photo from '../../assets/photo.jpg';

import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';

function PropHero(props) {
    const [show, setShow] = useState(false);

    const socialContent = [
        { id: 1, liveLink: 'https://www.facebook.com/people/Mypropertyplus/100084808515644/', icon: 'fa-facebook' },
        { id: 2, liveLink: 'https://www.instagram.com/mypropertyplus/', icon: 'fa-instagram' },
        { id: 3, liveLink: 'https://www.youtube.com/channel/UCzInr776Ip7jPRtfXbujROw', icon: 'fa-youtube' },
    ];

    console.log(props?.images);

    return (
        <div className="flex flex-col gap-10 mt-20 w-[100%] px-[10%]">
            <div className="flex flex-row gap-2 items-center justify-between">
                <div className="flex flex-col">
                    <span className="text-[30px] font-semibold">{props?.name}</span>
                    <span>{props?.address}</span>
                </div>

                <div>
                    <ul className="flex flex-row gap-4 text-[30px] text-gray-500 ">
                        {socialContent.map((item) => (
                            <li className="hover:text-black cursor-pointer" key={item.id}>
                                <a href={item.liveLink} target="_blank" rel="noopener noreferrer">
                                    {item.id === 1 ? <BsFacebook /> : item.id === 2 ? <BsInstagram /> : <BsYoutube />}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-[2fr_1fr] gap-10 items-center justify-center">
                <div>
                    <img className="rounded-[30px] w-[100%] h-[300px] md:h-[500px] object-cover" src={props?.images?.length > 0 ? props?.images[0] : photo} alt="" />
                </div>

                <div>
                    {props.images && props.images.length > 0 && (
                        <SlideshowLightbox className="grid grid-cols-2 gap-4 md:h-[500px] items-center justify-center">
                            {props.images.map((data, i) => (
                                <img
                                    className={`rounded-[10px] ${i>=6 ? " hidden" : ""}`}
                                    key={i}
                                    src={data}
                                    alt=""
                                    style={{ height: "150px", width: "100%" }} // Set the height inline
                                />
                            ))}
                        </SlideshowLightbox>
                    )}

                </div>
            </div>
        </div>
    );
}

export default PropHero;

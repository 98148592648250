import React, { useState } from 'react';
import axios from 'axios';

const AddBulk = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await axios.post('/api/property/bulk', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log(response.data); // Handle the response data as per your requirement

        setSelectedFile(null); // Clear the selected file after successful upload
      } catch (error) {
        console.error(error);
        // Handle error scenarios
      }
    }
  };

  return (
    <div>
      <h2>Bulk Property Upload</h2>
      <form onSubmit={handleFormSubmit}>
        <input type="file" accept=".xlsx,.xls" onChange={handleFileChange} />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
};

export default AddBulk;
